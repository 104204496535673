<template>
  <div class="member">
    <van-pull-refresh v-model="loading" @refresh="onRefresh" style="min-height: 100vh;">
      <van-notice-bar
          left-icon="volume-o"
          :scrollable="false"
          background="rgba(255,255,255,0.1)"
          color="#d9b79e"
      >
        <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in config.notices">{{item.text}}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>

      <div style="margin: 0 5px 8px 10px">
        <van-row >
          <van-col span="4" v-show="custom_version <= 2885">
            <van-image style="margin-top: 10px"
                       @click="toSavedChat()"
                       :src="info.photoBase64 ? 'data:image/png;base64,'+info.photoBase64 : require('../assets/logo.png')"
                       round fit="cover" height="4.1rem" width="4.1rem"/>
          </van-col>
          <van-col span="19" offset="1">
            <span style="color:#eeedee;font-size: 16px;display: block;font-weight: bolder;margin: 10px 0 5px 0">
              设备号：{{info.deviceId}}
              <van-tag v-if="info.deviceId" color=" #ce7f76" style="margin: 0 10px 4px 6px"
                       :data-clipboard-text="info.deviceId"
                       id="cp_DeviceId"  @click="copy('#cp_DeviceId')" >复制</van-tag>
            </span>

            <span style="color:#b9bdcb;display: block;font-weight: bolder;font-size: 12px;">
              <span class="van-ellipsis" style="font-size: 12px;">账户名：{{info.username}}</span>
              <van-tag v-if="info.dc_id" color="linear-gradient(to right, #f8a967, #f25693, #f56670)" style="margin: 0 5px 4px 6px" >DC {{ info.dc_id }}</van-tag>

              <van-tag v-if="info.id" color="linear-gradient(to right, #f8a967, #f25693, #f56670)" style="margin: 0 10px 4px 6px"
                       :data-clipboard-text="info.id"
                       id="cp_DeviceId"  @click="copy('#cp_DeviceId')" >ID {{ info.id }}</van-tag>
            </span>

            <span style="color:#b9bdcb;font-size: 12px;font-weight: bolder;">
              到期时间：{{info.endTime}}
              <van-tag :type="info.statusType" style="margin-left: 5px">{{info.deviceStatus}}</van-tag>
            </span>
          </van-col>
        </van-row>

      </div>

      <!--      <div style="border-top-left-radius: 10px;border-top-right-radius: 10px;padding: 0 10px;-->
      <!--      background-image: linear-gradient(110deg, #eecdc0, #f9ece4, #966f8a);margin: 0 10px">-->

      <!--        <van-image :src="require('../assets/teleph_vip.png')"  height="1.7rem" width="6rem" style="margin: 10px 0 0 5px"/>-->
      <!--        <span style="margin: 10px 10px 0 0; float: right;color: #905c53;font-size: 14px">-->
      <!--          <span style="padding-bottom: 5px;font-family: 'Bookman Old Style';font-style: italic ">-->
      <!--            version : {{ custom_version }}-->
      <!--          </span>-->
      <!--            </span>-->

      <!--        <van-config-provider  :theme-vars="gridThemeVars">-->
      <!--          <van-grid :border="false" :column-num="4" square>-->
      <!--            <van-grid-item icon="bookmark"  icon-color="#2e3b61" text="收藏夹"  @click="toSavedChat()"/>-->
      <!--            <van-grid-item icon="friends" icon-color="#2e3b61" text="用户群"  @click="addgroup('@tgzhvip')"/>-->
      <!--            <van-grid-item icon="volume" icon-color="#2e3b61" text="用户频道"  @click="addgroup('@tgzh_vip')"/>-->
      <!--            <van-grid-item icon="location" icon-color="#2e3b61" text="网站" @click="openByBrowser('http://www.teleph.top')"/>-->
      <!--            &lt;!&ndash;            <van-grid-item icon="chat" icon-color="#2e3b61" text="客服" @click="openByBrowser('http://chat.teleph.me')"/>&ndash;&gt;-->
      <!--          </van-grid>-->
      <!--        </van-config-provider>-->


      <!--      </div>-->

      <!--      pay-->
      <div :style="{width: '100%',height: screeHeight - 100,backgroundColor: '#efefef',
      borderTopLeftRadius: '10px', borderTopRightRadius: '10px',paddingTop: '1px',}" >

        <!--            lunbo-->
        <van-swipe style="height: 60px;margin: 10px 15px;border-radius: 10px"
                   :autoplay="5000"  :show-indicators=false>
          <van-swipe-item v-for="(item, index) in lunBo">
            <van-image
                @click="presentFragment(item.click_url, '在线客服')"
                radius="10"
                :width="screenWidth"
                height="4rem"
                :src=item.img_url
            />
          </van-swipe-item>
        </van-swipe>

        <!--&lt;!&ndash;        lianxikefu &ndash;&gt;-->
        <!--        <div @click="to_chat" style="background-color: #fae8de;border-radius: 10px;padding: 5px 10px;-->
        <!--        margin: 8px 15px;">-->
        <!--          <van-row>-->
        <!--            <van-col span="16">-->
        <!--              <span style="color: #65310b;font-size: 20px;font-weight: bold">点击此处， 联系客服</span>-->
        <!--            </van-col>-->
        <!--            <van-col offset="6" span="2">-->
        <!--              <van-icon name="play" color="#65310b" size="25"/>-->
        <!--            </van-col>-->
        <!--          </van-row>-->
        <!--        </div>-->


        <p style="font-weight: bold;font-size: 18px;margin: 8px 15px;font-family: '微软雅黑'">时长套餐</p>
        <van-row style="margin: 0 15px" gutter="10">
          <van-col span="8" v-for="(item ,index) in price" @click="select_price(index)" :key="index"
          >
            <div :style="{background: current_price_index == index ? select_bg_color_style : '#f3f3f3' ,borderRadius: '10px',padding: '1px',
                border: current_price_index == index ?  select_border_style : border_style,
           }">
              <div v-if="index == 2" style="height: 18px;width: fit-content;background: linear-gradient(110deg, #3199fc, #819bf8, #ee5ab4)
              ;border-top-left-radius: 9px;border-bottom-right-radius: 8px;padding: 0 5px">
                <span style="font-size: 11px;color: #f8f8f8;">{{item.top_text}}</span>
              </div>
              <div v-else style="height: 18px;width: fit-content;background:#cc7b6b;border-top-left-radius: 9px;border-bottom-right-radius: 8px;padding: 0 5px">
                <span style="font-size: 11px;color: #f8f8f8">{{item.top_text}}</span>
              </div>

              <div style="margin-top: 10px;text-align: center;">
              <span :style="{color: current_price_index == index ? select_price_text_color_style : '#00000087',fontWeight: 'bold', fontFamily: '楷体', fontsize: '28px'}">
                {{item.type}}卡
                </span>
                <p :style="{color: current_price_index == index ? select_price_text_color_style : '#00000087', margin: '5px 0', textAlign: 'center'}">
                  <span style="font-size: 1rem">￥</span>
                  <span style="font-size: 2rem;font-weight: bold">{{item.money}}</span></p>
              </div>
              <div :style="{height: '18px',borderRadius: '3px',background: current_price_index == index ? select_time_color  : time_color , textAlign: 'center',
            fontSize: '0.7rem', margin: '5px',color: current_price_index == index ? select_price_botoom_text_color_style : '#00000087'}">
                每月仅 ￥{{item.money / item.mouths}}
              </div>

            </div>
            <!--        </div>-->
          </van-col>
        </van-row>

        <van-radio-group style="margin: 10px 0"  v-model="checked">
          <van-config-provider  :theme-vars="CellThemeVars">
            <van-cell style="background: rgba(255,255,255,0)" clickable @click="checked = '1'">
              <template #title>
                <van-row gutter="20">
                  <van-col span="1">
                    <van-image
                        width="1.5rem"
                        height="1.5rem"
                        fit="contain"
                        :src="require('../assets/member/zhifubao.png')"
                    />
                    <!--                    <van-icon name="alipay" style="vertxical-align: middle" color="#1676fc" size="28" />-->
                  </van-col>
                  <van-col span="22">
                    <span style="margin-left: 10px;height: 12px;margin-bottom: 15px">扫码支付</span>
                  </van-col>
                </van-row>
              </template>

              <template #right-icon>
                <van-radio name="1" />
              </template>
            </van-cell>

<!--                        <van-cell style="background: rgba(255,255,255,0)" clickable @click="checked = '2'">-->
<!--                          <template #title>-->
<!--                            <van-row gutter="20">-->
<!--                              <van-col span="1">-->
<!--                                <van-image-->
<!--                                    width="1.5rem"-->
<!--                                    height="1.5rem"-->
<!--                                    fit="contain"-->
<!--                                    :src="require('../assets/member/zhifubao.png')"-->
<!--                                />-->
<!--            &lt;!&ndash;/*                    <van-icon name="alipay" style="vertxical-align: middle" color="#1676fc" size="28" />*/&ndash;&gt;-->
<!--                              </van-col>-->
<!--                              <van-col span="22">-->
<!--                                <span style="margin-left: 10px;height: 12px;margin-bottom: 15px">-->
<!--                                  App支付-->
<!--                                  <van-image-->
<!--                                      width="1.2rem"-->
<!--                                      height="1.2rem"-->
<!--                                      fit="contain"-->
<!--                                      :src="require('../assets/member/zhifubao.png')"-->
<!--                                  />-->
<!--                                </span>-->

<!--                              </van-col>-->
<!--                            </van-row>-->
<!--                          </template>-->

<!--                          <template #right-icon>-->
<!--                            <van-radio name="2" />-->
<!--                          </template>-->
<!--                        </van-cell>-->
          </van-config-provider>
        </van-radio-group>


        <!--        <van-divider-->
        <!--            :style="{ color: '#5e5e5f', borderColor: '#4a4a4b', padding: '0 16px' }"-->
        <!--        >-->
        <!--          <span style="color: #5656fc;font-weight: bold" @click="openByBrowser('http://www.teleph.top')">Telegram | Teleph</span>-->
        <!--        </van-divider>-->


      </div>
    </van-pull-refresh>
    <van-popup
        :overlay="false"
        :close-on-click-overlay="false"
        :lock-scroll="false"
        v-model:show="buy_show"
        round
        position="bottom"
    >
      <div style=" padding: 15px">
        <van-row>
          <van-col span="6">
            <span style="font-size: 15px;font-family: 楷体;color: #585757;font-weight: bold">{{current_time.top_text}}</span>
            <p :style="{color: select_price_text_color_style , margin: '5px 0',}">
              <span style="font-size: 1rem">￥</span>
              <span style="font-size: 2rem;font-weight: bold">{{current_time.money}}</span></p>
          </van-col>
          <van-col span="18">
            <van-button block round @click="onSubmit" color="linear-gradient(to right, #f8a967, #f25693, #f56670)">
              立即开通
            </van-button>
          </van-col>

          <!--          <van-col offset="2" span="4">-->
          <!--            <van-button block round @click="to_chat" color="linear-gradient(to right, #7232dd,  #f56670)">-->
          <!--              客服-->
          <!--            </van-button>-->
          <!--          </van-col>-->
        </van-row>

        <!--        <span style="text-align: center;font-size: 12px;color: #c0947a-->
        <!--        ;margin: 20px 20px 60px 20px;font-weight: bold;margin: 0"-->
        <!--        >-->
        <!--          注意：<span style="color: #f31313;">1.若支付后不可使用，请退出重进软件。</span>-->
        <!--          <br>2.若出现支付或其他问题，请联系-->
        <!--        <span-->
        <!--            v-if="config.WX"-->
        <!--            :data-clipboard-text="config.WX"-->
        <!--            id="wx"-->
        <!--            @click="copy('#wx')"-->

        <!--        >微信：<span style="color: rgba(100,223,190,0.78);text-decoration: underline">-->
        <!--          {{config.WX }}</span>, </span-->
        <!--        >-->
        <!--          <span-->
        <!--              v-if="config.QQ"-->
        <!--              :data-clipboard-text="config.QQ"-->
        <!--              id="qq"-->
        <!--              @click="copy('#qq')"-->

        <!--          >QQ：<span style="color: rgba(100,223,190,0.78);text-decoration: underline">-->
        <!--          {{config.QQ }}</span>, </span-->
        <!--          >-->
        <!--        飞机客服： <span style="color: rgba(100,223,190,0.78);text-decoration: underline" @click="addgroup(config.TG)">{{config.TG}}</span>-->


        <!--          &lt;!&ndash;          <span @click="link_to({click_url: 'http://chat.teleph.me/', title: '客服', type: 'presentFragment'},)"&ndash;&gt;-->
        <!--          &lt;!&ndash;                style="color: rgba(100,223,190,0.78);text-decoration: underline">客服</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;          微信：&ndash;&gt;-->
        <!--          &lt;!&ndash;          <span&ndash;&gt;-->
        <!--          &lt;!&ndash;              :data-clipboard-text="wx"&ndash;&gt;-->
        <!--          &lt;!&ndash;              id="wx"&ndash;&gt;-->
        <!--          &lt;!&ndash;              @click="copy('#wx')"&ndash;&gt;-->
        <!--          &lt;!&ndash;              style="color: rgba(100,223,190,0.78);text-decoration: underline"&ndash;&gt;-->
        <!--          &lt;!&ndash;          >{{ wx }}</span&ndash;&gt;-->
        <!--          &lt;!&ndash;          >&ndash;&gt;-->
        <!--          进行人工处理。-->
        <!--        </span>-->
      </div>
    </van-popup>

    <!--  dialog  -->
    <van-dialog v-model:show="PayShow" @confirm="check_trade "
                width="70%"
                show-cancel-button style="text-align: center">
      <template #title>
        <p style="margin: 0;text-align: center;font-size: 18px;font-weight: bold;color: #454948">
          支付宝扫码<br><span style="font-size: 13px">(可截图后扫码)</span></p>
      </template>
      <qrcode-vue :value="qrcode_str" :size="qrcodeSize" level="H" />
      <!--        <p style="margin: 5px;color: rgba(100,223,190,0.78)">请放心使用！！千万用户的选择！！</p>-->
    </van-dialog>


  </div>

</template>

<script>
import {ref, onMounted, getCurrentInstance, watch} from 'vue'
import axios from 'axios'
import { showNotify  ,Toast } from 'vant';
import clipboard from "clipboard";
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'Member',
  components:{
    QrcodeVue
  },
  setup(props) {
    const gridThemeVars = {
      gridItemContentPadding: '0',
      gridItemContentBackgroundColor: 'rgba(255,255,255,0)',
      gridItemIconSize: '41px',
      gridItemTextColor: '#2e3b61'
    };
    const CellThemeVars = {
      cellBackgroundColor: 'rgba(255,255,255,0)',
      cellGroupBackgroundColor: 'rgba(255,255,255,0)'
    }

    const screenWidth= ref(document.documentElement.clientWidth - 30)      // 屏幕宽
    const screeHeight= ref(document.documentElement.clientHeight - 45)   // 屏幕高

    function handleScrollx() {
      screenWidth.value = document.documentElement.clientWidth - 30
      screeHeight.value = document.documentElement.clientHeight - 45;
      console.log('screenWidth', screenWidth.value, 'screeHeight', screeHeight.value)
    }

    watch(
        () => document.documentElement.clientHeight,
        (pev, old) => {
          window.addEventListener("resize", handleScrollx, true);
        }
    )

    const lunBo = ref([
      {img_url:require('../assets/member/lunbo/chat.png'), click_url: 'https://teleph.app/chat/', title: '客服', type: 'byBrowser'},
      // {img_url:require('../assets/lunbo/search.png'), click_url: 'http://search.teleph.cn/', title: 'SearchCN', type: 'presentFragment'},
      // {img_url:require('../assets/lunbo/invited.png'), click_url: 'http://invited.teleph.me/', title: 'InvitedTitle', type: 'presentFragment'},
      // {img_url:require('../assets/lunbo/zhaoshang.png'), click_url: '@wThank', title: '', type: 'addgroup'},
    ])
    const api = ref('https://teleph.app:8984')

    const loading = ref(false);
    const onRefresh = () => {
      setTimeout(() => {
        loading.value = false;
        window.location.reload()
      }, 300);
    };

    const user = ref({})

    const info = ref(
        {
          username: 'Telegram | Teleph',
          id: '',
          dc_id: '',
          deviceId: '',
          endTime: '未获取',
          deviceStatus: '状态未获取',
          statusType: 'danger',
          pone: '',
          photoBase64: '',
        },
    )
    const custom_version = ref(1000)
    const price = ref([
      {type: '月', money: 39, mouths: 1, top_text: '包月仅39元'},
      {type: '季', money: 69, mouths: 3, top_text: '季卡仅69元'},
      {type: '年', money: 99, mouths: 12, top_text: '特惠年卡'},
    ])
    const checked = ref('1');

    const config = ref({
      QQ: "409987134",
      WX: "telephrog",
      TG: "@zh_cntg",
      notices: [
        {"text": "邀请页邀请好友下载安装，双人免费领取时长"},
        {"text": "充值时长只用于此设备，与TG账户无关！！"},
        {"text": "软件不可双开！！可用时长不互通！！"},
        {"text": "软件不可双开！！可用时长不互通！！"},
        {"text": "充值时长只用于此设备，与TG账户无关！！"},
        {"text": "恢复出厂设置、系统升级操作，时长将清零"},
        {"text": "充值时长只用于此设备，与TG账户无关！！"},
        {"text": "充值时长只用于此设备，与TG账户无关！！"}
      ]
    })

    const showPopover = ref(true);
    const buy_show = ref(true);
    const current_price_index = ref(2)
    const current_time = ref(price.value[current_price_index.value])

    const PayShow = ref(false)
    const qrcode_str = ref('')
    const qrcodeSize = ref(200)
    const Out_trade_no = ref('')

    const time_color = ref('#eeeded')
    const select_time_color = ref('#fae8de')

    const border_style = ref(
        '#eeeded 2px solid'
    )
    const select_border_style = ref(
        '#d9b79e 2px solid'
    )
    const select_bg_color_style = ref(
        '#faf5f1'
    )
    const select_price_text_color_style = ref(
        '#65310b'
    )
    const select_price_botoom_text_color_style = ref(
        '#c0947a'
    )

    onMounted(() => {
      window.addEventListener("resize", handleScrollx, true)
      window.queryStatus = queryStatus;
      window.setUser = setUser;

      getNotices()
      getAppDeviceId();
      queryStatus(info.value.deviceId)
      getCurrentUser();
      getCUserPhoto();
      getCustomVersion();

    })

    const setUser = (userStr) => {
      const userIfo = JSON.parse(userStr)
      info.value.username = userIfo.username
      return "setUser"
    }

    const getNotices = async () => {
      try {
        let res = await axios.get(encodeURI('https://teleph.app/config.json'))
        config.value = res.data
      }catch (e) {
        console.log(e)
      }
    }

    const addgroup = (val) => {
      console.log(val);
      if (val.startsWith('@')){
        /*约定的url协议为：js://webview?arg1=111&arg2=222*/
        document.location = "appjs://webview?path=" + val;
        console.log(document.location);
      }else {
        document.location = val
      }
    }

    const onSubmit = () => {
      create()
    };

    const select_price = (index) => {
      current_price_index.value = index
      current_time.value = price.value[index]
    }

    const copy = (id) => {
      const clipboard1 = new clipboard(id);
      clipboard1.on("success", () => {
        showNotify ({ type: 'success', message: '内容已复制到剪贴板' });
        // 释放内存
        clipboard1.destroy();
      });
      clipboard1.on("error", () => {
        showNotify ({ type: 'danger', message: '内容复制失败' });
        // 释放内存
        clipboard1.destroy();
      });
    }

    const create = () => {

      if (!info.value.deviceId) {
        showNotify ({ type: 'danger', message: '未获取到设备号，请重启软件重试' });
        return;
      }

      axios({
        method: "POST",
        url: api.value+"/xunpay/create",
        params: {
          imei: info.value.deviceId,
          money: current_time.value.money,
          months: current_time.value.mouths,
          type: checked.value === '2' ? 'WAP' : '',
          returnUrl: 'https://teleph.app'
        }
      })
          .then(res => {
            console.log('create', res);
            if (res.data.code == 200){
              if (checked.value === '2'){
                document.querySelector('body').innerHTML = res.data.data.wap;
                document.forms[0].submit();
              }else {
                qrcode_str.value = res.data.data.qrCode;
                Out_trade_no.value = res.data.data.Out_trade_no
                //展示 二维码
                PayShow.value = true
              }

            }else {
              console.log(res.data.data.Msg);
              showNotify ({ type: 'danger', message: res.data.data.Msg });
            }

          })
          .catch(err => {
            console.log(err);
            showNotify ({ type: 'danger', message: '网络错误！请稍后再试！' });
          });
    }

    const queryStatus = (Imei) => {
      // info.value.deviceId = Imei;
      if (!info.value.deviceId || !Imei){
        getAppDeviceId();
      }

      if (!info.value.deviceId ){
        showNotify ({ type: 'danger', message: '未获取到设备号，请重启软件重试' });
        return
      }

      // alert('deviceID:', info.value.deviceId)
      axios
          .get(api.value+"/xunpay/query/" + info.value.deviceId,
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded"
                }
              })
          .then(res => {
            console.log('queryStatus', res);
            info.value.endTime = res.data.data.end_at;
            info.value.deviceStatus = res.data.data.msg;
            let end_timestamp = res.data.data.end_timestamp
            let now = new Date().getTime() / 1000
            info.value.statusType = end_timestamp > now ? 'success' : 'danger'

            setAppStatus();
          })
          .catch(err => {
            console.log(err);
            showNotify ({ type: 'danger', message: '网络错误！请稍后再试！' });
          });
    }

    const check_trade = () => {
      if (!Out_trade_no.value){
        return
      }
      PayShow.value = false,
          axios({
            method: "get",
            url: api.value+"/xunpay/queryState/"+Out_trade_no.value,
          }).then((res) => {
            console.log(res.data.msg)
            let status = res.data.data.tradeStatus
            let type = 'danger'
            if (status == 'TRADE_SUCCESS' || status == 'TRADE_FINISHED'){
              type = 'success'
            }
            showNotify ({ type: type, message: res.data.msg });
          }).catch(err => console.log(err))
      queryStatus(info.value.deviceId);
    }

    const link_to = (item) => {
      console.log(item.type)
      switch (item.type){
        case 'presentFragment':
          presentFragment(item.click_url, item.title)
          break;
        case 'addgroup':
          addgroup(item.click_url)
          break;
        case 'byBrowser':
          openByBrowser(item.click_url)
          break
        default:
          break
      }
    }

    const presentFragment = (url, title) => {
      try {
        $App.present_Fragment(url, title);
      } catch (e) {
        console.log("$APP presentFragment err" + e);
      }
    }

    const setAppStatus = () => {
      try {
        $App.setMemberStatus();
      } catch (e) {
        console.log("$APP setAppStatus err" + e);
      }
    }

    const Favorites = () => {
      try {
        $App.present_Favorites();
      } catch (e) {
        console.log("$APP Favorites err" + e);
      }
    }

    const openByBrowser = (url) => {
      try {
        $App.openByBrowser(url);
      } catch (e) {
        console.log("$APP openByBrowser err" + e);
      }
    }

    const getAppDeviceId = () => {
      try {
        info.value.deviceId = $App.getDeviceNo();
      } catch (e) {
        console.log("$APP getAppDeviceId err" + e);
      }
    }

    const getCUserPhoto = () => {
      try {
        info.value.photoBase64 = $App.getCUserPhoto();
      } catch (e) {
        console.log("$APP getCUserPhoto err" + e);
      }
    }

    const getCurrentUser = () => {
      try {
        let user_str = $App.getCurrentUser();
        const userIfo = JSON.parse(user_str)
        user.value = userIfo;
        let last_name = !!userIfo.last_name ? !!userIfo.last_name : '' ;
        info.value.username = userIfo.username ? userIfo.username : userIfo.first_name + ' ' + last_name;
        info.value.id = userIfo.id;
        info.value.dc_id = userIfo.photo.dc_id;
      } catch (e) {
        console.log("$App getCurrentUser err", e);
      }
    }

    const toSavedChat = () => {
      if (custom_version.value <= 2885){
        Favorites()
      }else {
        try {
          $App.toSavedChat();
        } catch (e) {
          console.log("$App toSavedChat err", e);
        }
      }
    }

    const  getCustomVersion = () => {
      try {
        custom_version.value = $App.getCustomVersion();
      } catch (e) {
        console.log("$App getCustomVersion err", e);
      }
    }

    const to_chat = () => {
      location.href =
          'http://chat.teleph.me?customer={"昵称":'+info.value.username+' ,' +
          '"设备号":'+info.value.deviceId+', ' +
          '"到期时间": '+info.value.endTime+',' +
          '"设备状态": '+info.value.deviceStatus+'}'
    }

    return{
      info, addgroup, custom_version, price, onSubmit, checked, showPopover, lunBo, screenWidth, screeHeight,
      current_price_index, time_color, select_time_color, select_price, border_style, select_border_style, link_to, openByBrowser,
      current_time, copy, PayShow, qrcode_str, qrcodeSize, check_trade, presentFragment, loading, CellThemeVars,Favorites,
      onRefresh, gridThemeVars, select_bg_color_style, select_price_text_color_style, select_price_botoom_text_color_style, buy_show, config,
      to_chat, toSavedChat
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.notice-swipe {
  height: 30px;
  line-height: 30px;
  font-weight: bold;
}
.custom-title {
  margin-left: 10px;
  vertical-align: middle;
  font-size: 14px;
  font-family: 微软雅黑;
  margin-bottom: 10px;
}
.member{
  background-image: url("../assets/bg/MemberBG.png");
  height: 400px;
  background-size: cover;
}
html , body {
  height: 100%;
}
</style>
