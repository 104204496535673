import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MemberView from '../views/MemberView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => location.href = 'https://web.teleph.app'
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/search',
    name: 'search',
    component: () => import( '../views/SearchView.vue')
  },
  {
    path: '/invited',
    name: 'invited',
    component: () => import( '../views/InvitedView.vue')
  },
  {
    path: '/prevent',
    name: 'prevent',
    component: () => import('../views/PreventLossView.vue')
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => location.href = 'https://teleph.app/chat.html'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
