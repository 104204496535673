import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import './assets/glable.css'
import {Axios} from "axios";
// @ts-ignore
import {CryptoJS} from 'crypto-js';


const app = createApp(App);
app.use(Vant);
app.provide('$axios', Axios)
app.provide('$CryptoJS', CryptoJS)
app.use(router).mount('#app')
